import React from 'react';
import DetailedStepsButton from './DetailedStepsButton';
import classes from '../../assets/css/components/DetailedStepsCard.module.scss';

function DetailedStepsCard({
  isActive,
  cardIcon,
  backgroundColor,
  description,
  buttonColor,
  buttonIcon,
  buttonName,
  onCardButtonClick,
}) {
  return (
    <div
      className={classes.cardContainer}
      style={{ backgroundColor: backgroundColor }}
      data-testid='detailed-steps-card'
    >
      <img className={classes.cardIcon} src={cardIcon} alt='cardIcon' />
      <div className={classes.cardContent}>
        <span>{description}</span>
        {isActive ? (
          <DetailedStepsButton
            backgroundColor={buttonColor}
            iconUrl={buttonIcon}
            onClick={onCardButtonClick}
          >
            {buttonName}
          </DetailedStepsButton>
        ) : (
          <DetailedStepsButton backgroundColor='rgba(204, 228, 247, 0.50)'>
            Coming soon
          </DetailedStepsButton>
        )}
      </div>
    </div>
  );
}

export default DetailedStepsCard;
