import React from 'react';
import classes from '../../assets/css/components/PplHeader.module.scss';
const PplHeaderTabs = ({ tabs }) => {
  const tabColors = ['#54A6FF', '#4BC7B7', '#FFBB54'];
  return (
    <div className={classes.tabsContainer} data-testid='ppl-header-tabs'>
      {tabs?.map((tab, index) => (
        <div key={`tab-${index}`} className={classes.tab}>
          <div
            className={classes.tabDot}
            style={{ backgroundColor: tabColors[index] ? tabColors[index] : '#A1A1A2' }}
          />
          <div className={classes.tabText}>{tab}</div>
        </div>
      ))}
    </div>
  );
};

export default PplHeaderTabs;
