import React from 'react';
import PropTypes from 'prop-types';
import classes from '../../assets/css/components/MenuButtons.module.scss';
import tinycolor from 'tinycolor2';

const MenuButtons = ({ buttonList, backgroundColor }) => {
  return (
    <div data-testid='main-component' className={`${classes.menuButtons}`}>
      {buttonList.map((item, index) => {
        return (
          <div
            onClick={item.onClick}
            className={classes.button}
            style={{ background: `rgba(${backgroundColor})` }}
            key={index}
          >
            <img src={item.icon} alt='image' />
            <span style={tinycolor(`rgba(${backgroundColor})`).isLight() ? { color: '#282F54' } : { color: '#fafafa' }}>{item.title}</span>
          </div>
        );
      })}
    </div>
  );
};

MenuButtons.propTypes = {
  buttonList: PropTypes.array,
};

MenuButtons.defaultProps = {
  buttonList: [],
};
export default MenuButtons;
