import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import MenuButtons from '../../components/MenuButtons/MenuButtons';
import MenuStoryBanner from '../../components/MenuStoryBanner/MenuStoryBanner';
import logo from '../../assets/images/Blueprint_logo.svg';
import MenuNav from '../../components/MenuNav/MenuNav';
import '../../assets/css/pages/LandingPage.scss';
import MenuItem from '../../components/MenuItems/MenuItem';
import SecondaryMenuItem from '../../components/SecondaryMenuItem/SecondaryMenuItem';
import { getMethod } from '../../services/commonServices';
import { MenuAPI } from '../../utils/apiConstant';
import { landingPageContext } from '../../context/LandingPageContextProvider';

const LandingPage = () => {
  const { state, setMenuResponse, setCurrentTab } = useContext(landingPageContext);

  const navigate = useNavigate();
  const [storyBannerAnimation, setStoryBannerAnimation] = useState('banner-fade-in');
  const response = state.menuResponse;
  const currentTab = state.currentTab;
  const selectedNavData = response?.menuItems?.find((item) => item.menuItemId === currentTab);
  const getMenuResponse = async () => {
    const response = await getMethod(MenuAPI);
    response.headerItems.map((item) => {
      if (item.title === 'Find out more') {
        item.onClick = () => navigate(`/Findoutmore`);
      } else item.onClick = () => navigate(`/Glossary`);
    });

    setMenuResponse(response);
  };

  useEffect(() => {
    getMenuResponse();
  }, []);
  return (
    <div className='landing-page' data-testid='landing-page'>
      {response !== null && (
        <>
          <div className='nav-button-container ml-5 mr-5 mt-2.5 mb-2'>
            <div className='nav-button'>
              <MenuButtons buttonList={response.headerItems} />
            </div>
            <div className='nav-logo'>
              <img src={logo} alt='logo' />
            </div>
          </div>
          <MenuNav
            menuResponse={response}
            setCurrentTab={setCurrentTab}
            currentTab={currentTab}
            setStoryAnimation={setStoryBannerAnimation}
            items={state.navItems}
          />
          <div className='ml-5 mr-5'>
            <MenuStoryBanner
              key={`menu-story-${Math.random()}`}
              animation={storyBannerAnimation}
              title={selectedNavData.story.text}
              imageSrc={selectedNavData.story.image}
              backgroundColor={selectedNavData.story.backgroundColour}
              onBannerClick={() => navigate(`stories?category=${selectedNavData.menuItemId}`)}
            />
            {selectedNavData.collections.map((collection, index) => {
              if (collection.title !== 'Delivered' && collection.title !== 'In Progress') {
                return (
                  <React.Fragment key={`collection-${index}`}>
                    <div className='menu-title'>
                      <span
                        className={`${
                          collection.title === 'Find out more'
                            ? 'subtitle'
                            : 'subtitle subtitle_two_medium'
                        } lloyds-text-white pb-1.5`}
                      >
                        {collection.title}
                      </span>
                    </div>{' '}
                    <div className={'collection-items-container'}>
                      {collection.items.map((item, index) =>
                        item.title === 'Placing Platform Limited (PPL)' ? (
                          <SecondaryMenuItem
                            menu={item}
                            isPPL={true}
                            key={`collection-item-${index}`}
                            bgColor={item.backgroundColour}
                            onMenuItemClick={() => {
                              navigate('ppl');
                            }}
                          />
                        ) : (
                          <MenuItem
                            title={item.title}
                            icon={item.icon}
                            isActive={item.isActive}
                            key={`collection-item-${index}`}
                            bgColor={item.backgroundColour}
                            onMenuItemClick={() =>
                              item.isActive &&
                              navigate(
                                `${
                                  item.title === 'Journey Overview' ? 'journey' : 'detailedsteps'
                                }?category=${selectedNavData.menuItemId}&subcategory=${
                                  collection.title
                                }`,
                              )
                            }
                          />
                        ),
                      )}{' '}
                    </div>
                  </React.Fragment>
                );
              }
            })}
          </div>
          <div className='scroll-container'>
            {selectedNavData.collections.map((collection) => {
              if (collection.title === 'Delivered' || collection.title === 'In Progress') {
                return (
                  <div
                    className={collection.title === 'Delivered' ? 'delivery' : 'in-progress'}
                    key={`${collection.title}`}
                  >
                    <div className='menu-title'>
                      <span className='subtitle lloyds-text-white pb-1.5'>{collection.title}</span>
                    </div>
                    <div className='collection-items-container'>
                      {collection.items.map((item, index) => (
                        <SecondaryMenuItem
                          menu={item}
                          key={`collection-item-${index}`}
                          bgColor={item.backgroundColour}
                          onMenuItemClick={() => navigate(`/DeliveredInProgress?${item.title}`)}
                        />
                      ))}
                    </div>
                  </div>
                );
              }
            })}
          </div>
        </>
      )}
    </div>
  );
};

export default LandingPage;
