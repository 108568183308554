import React from 'react';
import classes from '../../assets/css/components/MenuItem.module.scss';
import tinycolor from 'tinycolor2';

const MenuItem = ({ title, icon, onMenuItemClick, isActive, bgColor }) => {
  const opacity = !isActive ? classes.inActive : '';
  return (
    <div
      data-testid='main-component'
      className={`${classes.button} ${opacity}`}
      style={{ backgroundColor: `${bgColor}` }}
      onClick={onMenuItemClick}
    >
      <div className={classes.rightSession}>
        <img src={icon} alt='image' />
        <span style={tinycolor(bgColor).isLight() ? { color: '#242F54' } : { color: '#FAFAFA' }}>
          {title}
        </span>
      </div>
      {!isActive && <div className={classes.comingSoon}>Coming Soon</div>}
    </div>
  );
};

export default MenuItem;
