import React, { useEffect, useState } from 'react';
import { getMethod } from '../../services/commonServices';
import { PplAPI } from '../../utils/apiConstant';
import PplHeader from '../../components/PplHeader/PplHeader';
import { useNavigate } from 'react-router-dom';
import PplBody from '../../components/PplBody/PplBody';

const PPL = () => {
  const [response, setResponse] = useState(null);
  const navigate = useNavigate();
  const setPPLResponse = async () => {
    const pplResponse = await getMethod(PplAPI);
    setResponse(pplResponse);
  };
  useEffect(() => {
    setPPLResponse();
  }, []);
  return (
    <div style={{ overflow: 'scroll', paddingBottom: "60rem" }} data-testid='ppl'>
      <PplHeader
        backgroundColor='rgba(40, 47, 84, 0.95)'
        onHomeClick={() => {
          navigate(-1);
        }}
        title={response?.title}
        pplTabs={response?.statusCollection}
      />
      <PplBody collection={response?.collection} image={response?.image} />
    </div>
  );
};

export default PPL;
