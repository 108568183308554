import React from 'react';
import classes from '../../assets/css/components/ProgressTimeline.module.scss';
import CompletedIcon from '../../assets/images/TimelineCompleted.svg';
import InProgressIcon from '../../assets/images/TimelineInProgress.svg';
import UpNextIcon from '../../assets/images/TimelineUpNext.svg';
import SolidLine from '../../assets/images/TimelineSolidLine.svg';
import DashedLine from '../../assets/images/TimelineDashedLine.svg';

function ProgressTimeline({steps}) {
    return (
        <div style={{ display: "flex" }} data-testid='progress-timeline'>
            {steps.map((step, index) => (
                <div key={`${step.name}-${index}`} className={classes.timelineContainer}>
                    <div className={classes.name}>
                        <span>{step.name}</span>
                    </div>
                    <div className={classes.progress}>
                        <span>{step.progressStatus === "COMPLETE" ? "Complete" : step.progressStatus === "IN PROGRESS" ? "In progress" : "Up next"}</span>
                    </div>
                    <div className={classes.timeline}>
                        <div className={classes.iconContainer}>
                            <img src={step.progressStatus === "COMPLETE" ? CompletedIcon : step.progressStatus === "IN PROGRESS" ? InProgressIcon : UpNextIcon} />
                        </div>
                        {index !== steps.length - 1 && <div className={classes.line}>
                            <img src={step.progressStatus === "COMPLETE" ? SolidLine : DashedLine} />
                        </div>}
                    </div>
                </div>
            ))}
        </div>
    )
}

export default ProgressTimeline