import React, { useEffect, useState } from 'react';
import DrawerTab from './DrawerTab';
import ProgressTimeline from './ProgressTimeline';
import classes from '../../assets/css/components/DrawerContent.module.scss';
import TickIcon from '../../assets/images/Tick.svg';
import DrawerFilterTab from './DrawerFilterTab';
import RelatedSteps from './RelatedSteps';

function DrawerContent({data}) {
    const [tabValue, setTabValue] = useState("Progress");
    const [filterValue, setFilterValue] = useState("All steps")
    const [filteredRelatedStepInfo, setFilteredRelatedStepInfo] = useState([])
    const [fadeIn, setFadeIn] = useState(false);

    useEffect(() => {
        setFilteredRelatedStepInfo(data.relatedStep.relatedStepInfo.filter((step) => step.flag.includes(filterValue)))
    }, [filterValue]);

    return (
        <div data-testid='drawer-content'>
            <DrawerTab
                tabNames={["Progress", "Architecture", "Related steps"]}
                value={tabValue}
                onChangeHandler={(value) => {
                setTabValue(value); 
                setFadeIn(true);
                setTimeout(() => {
                    setFadeIn(false);
                }, 300)
                }}
            />
            {tabValue === "Progress" ?
                <div className={fadeIn ? classes.fadeIn : ""}>
                    <div className={classes.progressTimeline}>
                        <ProgressTimeline steps={data.trackerInfo} />
                    </div>
                    {data.deliveryInfoTextCollection.map((section) => (
                        <div key={section.title} className={classes.progressTextContent}>
                            <div className={classes.progressTitle}>{section.title}</div>
                            {section.itemList.map((item) => (
                                <div key={item} className={classes.progressList}>
                                    {!item.startsWith("*") && <img src={TickIcon} alt="tick-icon" />}
                                    <div>{item}</div>
                                </div>
                            ))}
                        </div>
                    ))}
                </div> : tabValue === "Architecture" ?
                    <div className={`${classes.architecture} ${fadeIn ? classes.fadeIn : ""}`}>
                        <img src={data.architecture.image} alt="architecture-image" />
                    </div> :
                    <div className={`${classes.relatedStepsContainer} ${fadeIn ? classes.fadeIn : ""}`}>
                        <div className={classes.relatedStepDescription}>{data.relatedStep.relatedStepDescription}</div>
                        <DrawerFilterTab
                            filters={data.relatedStep.stepStatusList}
                            value={filterValue}
                            onChangeHandler={(value) => setFilterValue(value)}
                        />
                        <div className={classes.relatedStepsSteps}>
                            {filteredRelatedStepInfo.map((step, index) => (
                                <RelatedSteps
                                    key={step.stepDescription}
                                    stepDescription={step.stepDescription}
                                    backgroundColor={step.stepStatus === "Underwriter" || step.stepStatus === "Insurer" ? "#ffbb54" : step.stepStatus === "Back Office" ? "#a1a1a2" : "#54a6ff"}
                                    isActive={step.status.includes("Active")}
                                    bottomFlowLine={index !== filteredRelatedStepInfo.length - 1}
                                />
                            ))}
                            {!filteredRelatedStepInfo[0] && <div className={classes.relatedStepText}>The MRC v3 is not used in this phase of the journey</div>}
                        </div>
                    </div>
            }
        </div>
    )
}

export default DrawerContent