import React, { useEffect, useState } from 'react';
import DetailedStepsHeader from '../../components/DetailedStepsHeader/DetailedStepsHeader';
import Home from '../../assets/images/DetailedJourneyHome.svg';
import DataIcon from '../../assets/images/DataFirst.svg';
import DocumetIcon from '../../assets/images/DocumentFirst.svg';
import { useNavigate, useLocation } from 'react-router';
import '../../assets/css/pages/DetailedSteps.scss';
import DetailedStepsSection from '../../components/DetailedStepsSection/DetailedStepsSection';
import { getMethod } from '../../services/commonServices';
import { DetailedStepsAPI } from '../../utils/apiConstant';
import DetailedStepsModal from '../../components/DetailedStepsModal/DetailedStepsModal';
import DrawerComponent from '../../components/drawer/Drawer';
import DrawerContent from '../../components/DetailedStepsDrawerComponents/DrawerContent';
import { IdleTimerProvider } from 'react-idle-timer';

const DetailedSteps = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [data, setData] = useState({});
  const [filters, setFilters] = useState([]);
  const [filterValue, setFilterValue] = useState('All steps');
  const [dataDocumentFilter, setDataDocumentFilter] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [drawerData, setDrawerUpData] = useState({});
  const [summaryDrawerOpen, setSummaryDrawerOpen] = useState(false);
  const [summaryDrawerData, setSummaryDrawerData] = useState({});
  const [isExit, setIsExit] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const getDetailedStepsResponse = async () => {
    const response = await getMethod(DetailedStepsAPI + location.search);
    setData(response);
    setFilters(response.statusCollection);
    if (response.activePage.data.name && response.activePage.document.name) {
      setDataDocumentFilter(response.activePage.data.name);
      setModalOpen(true);
    }
  };

  useEffect(() => {
    getDetailedStepsResponse();
  }, []);

  const onCardButtonClick = (value) => {
    const panelData = data.panelCollection.find((panel) => panel.panelId === value);
    setDrawerUpData(panelData);
    setDrawerOpen(true);
  }

  const onSummaryButtonClick = (value, flagColor) => {
    if (value.length > 1) {
      if (dataDocumentFilter === "Document First") {
        const summaryData = value.find((summary) => summary.summaryType === "Document");
        setSummaryDrawerData({data: summaryData, flag: flagColor});
      } else {
        const summaryData = value.find((summary) => summary.summaryType === "Data");
        setSummaryDrawerData({data: summaryData, flag: flagColor});
      }
    } else {
      setSummaryDrawerData({data: value[0], flag: flagColor});
  }
    setSummaryDrawerOpen(true);
  }

  const onIdle = () => {
    setSummaryDrawerOpen(false);
    setDrawerOpen(false);
    setModalOpen(false);
  };

  return (
    <div className={`detailed-steps-page ${isExit ? "exitAnimation" : "entryAnimation"}`} data-testid='detailed-steps'>
      <IdleTimerProvider
        timeout={239800}
        onIdle={onIdle}
      ></IdleTimerProvider>
      <DetailedStepsModal
        open={modalOpen}
        setOpen={setModalOpen}
        setDataDocumentFilter={setDataDocumentFilter}
      />
      <DetailedStepsHeader
        title={data.title}
        imageSrc={Home}
        onHomeClick={() => {
          setIsExit(true);
          setTimeout(() => {
            navigate('/');
            setIsExit(false);
          }, 300)
        }}
        buttonName={dataDocumentFilter}
        buttonIcon={dataDocumentFilter === 'Data First' ? DataIcon : DocumetIcon}
        buttonBgColor={'204, 228, 247, 1'}
        onButtonClick={() => {
          setModalOpen(true);
        }}
        filters={filters}
        filterValue={filterValue}
        onFilterChange={(value) => {
          setFilterValue(value);
        }}
      />
      <div className='detailed-steps-body'>
        {data.marketItemCollection?.collection.map((section, index) => (
          <DetailedStepsSection
            key={section.collectionId}
            subTitlePrefix={section.subtitlePrefix}
            subTitle={section.subtitle}
            imgUrl={section.image}
            detailCards={section.cardItemCollection}
            statusCollection={filters}
            filterValue={filterValue}
            isLastSection={index === data.marketItemCollection.collection.length - 1}
            dataDocumentFilter={dataDocumentFilter}
            onCardButtonClick={(value) => onCardButtonClick(value)}
            onSummaryButtonClick={(value, flag) => onSummaryButtonClick(value, flag)}
          />
        ))}
      </div>
     <DrawerComponent 
      open={drawerOpen}
      title={drawerData.title}
      description={drawerData.description}
      image={drawerData.image}
      content={<DrawerContent data={drawerData.progress} />}
      onBannerClick={() => setDrawerOpen(false)}
      />
      <DrawerComponent 
      open={summaryDrawerOpen}
      title={summaryDrawerData.data?.title}
      description={summaryDrawerData.data?.subtitle}
      image={summaryDrawerData.data?.image}
      flagColor={summaryDrawerData.flag}
      onBannerClick={() => setSummaryDrawerOpen(false)}
      />
    </div>
  );
};

export default DetailedSteps;
