import React, { useContext, useEffect, useState } from 'react';
import { getMethod } from '../../services/commonServices';
import { JourneyAPI } from '../../utils/apiConstant';
import arrow from '../../assets/images/Button_prefix_arrow.svg';
import Home from '../../assets/images/All.svg';
import JourneyHeader from '../../components/JourneyHeader/JourneyHeader';
import '../../assets/css/pages/JourneyPage.scss';
import { journeyContext } from '../../context/JourneyContextProvider';
import JourneyBody from '../../components/JourneyBody/JourneyBody';
import { useNavigate } from 'react-router';

const JourneyOverview = () => {
  const [isExit, setExit] = useState(false);
  const { state, setCurrentTab, setJourneyResponse } = useContext(journeyContext);
  const urlParams = window.location.href.slice(window.location.href.indexOf('?') + 1);
  const navigate = useNavigate();
  const isPPS = localStorage.getItem("isPPS");
  
  const getJourneyResponse = async () => {
    const response = await getMethod(JourneyAPI + urlParams);
    setJourneyResponse({ response, locationSearch: urlParams });
  };
  useEffect(() => {
    getJourneyResponse();
  }, []);

  const buttonList = () => {
    if (state.painPoints.collection.length > 0 && state.solutions.collection.length > 0) {
      return [
        {
          title: 'View pain points',
          icon: arrow,
          onClick: () => {
            setCurrentTab('painPoints');
            localStorage.setItem("isPPS", "true");
          },
        },
        {
          title: 'View solutions',
          icon: arrow,
          onClick: () => {
            setCurrentTab('solutions');
            localStorage.setItem("isPPS", "true");
          },
        },
      ];
    } else if (state.painPoints.collection.length > 0) {
      return [
        {
          title: 'View pain points',
          icon: arrow,
          onClick: () => {
            setCurrentTab('painPoints');
            localStorage.setItem("isPPS", "true");
          },
        },
      ];
    } else if (state.solutions.collection.length > 0) {
      return [
        {
          title: 'View solutions',
          icon: arrow,
          onClick: () => {
            setCurrentTab('solutions');
            localStorage.setItem("isPPS", "true");
          },
        },
      ];
    }
    return [];
  };

  return (
    <div
      className={` ${isExit ? 'exitAnimation' : isPPS !== "true" ? 'entryAnimation' : ""} journey-page `}
      style={{height: "100%"}}
      data-testid='journey-page'
    >
      {state.journeyResponse && (
        <div>
          <JourneyHeader
            imageSrc={Home}
            title={state.journeyResponse.content.title}
            buttonList={buttonList()}
            backgroundColor={'#282f54f2'}
            onHomeClick={() => {
                localStorage.setItem("isPPS", "")
                setExit(true);
                setTimeout(() => {
                  setExit(false);
                  navigate("/");
                }, 300);
            }}
          />
          <hr />
          <JourneyBody
            journeyResponse={state.journeyResponse}
            page={urlParams.toLowerCase()}
          />
        </div>
      )}
    </div>
  );
};

export default JourneyOverview;
