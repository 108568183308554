import React, { useState } from 'react';
import JourneyHeaderCrossIcon from '../../assets/images/JourneyHeaderCrossIcon.svg';
import BackArrow from '../../assets/images/Back_Arrow.svg';
import ForwardArrow from '../../assets/images/Forward_Arrow.svg';
import classes from '../../assets/css/components/PainPointsSolutionsHeader.module.scss';

const PainPointsSolutionsHeader = ({
  title,
  backgroundColor,
  textColorClass,
  collectionData,
  currentIndex,
  setCurrentIndex,
  onCloseClick,
}) => {
  const [isFadeOut, setFadeOut] = useState(false);

  return (
    <div
      data-testid='Header-component'
      className={classes.headerBanner}
      style={{ background: backgroundColor }}
    >
      <div className={classes.headerContainer}>
        <div className={classes.headerIcon}>
          <img
            onClick={onCloseClick}
            src={JourneyHeaderCrossIcon}
            alt='bannerIcon'
          />
          <div className={classes.headerCircleContainer}>
            {collectionData.map((_, index) => (
              <div
                key={`header-circle-${index}`}
                className={`${classes.headerCircle} ${
                  index === currentIndex ? classes.headerCircleActive : ""
                }`}
              />
            ))}
          </div>
          <div className={classes.headerArrowContainer}>
            <img
              className={currentIndex === 0 ? classes.inactive : ""}
              onClick={() => {
                setFadeOut(true);
                setTimeout(() => {
                  setFadeOut(false);
                  setCurrentIndex({ action: 'decrement' });
                }, 300);
              }}
              src={BackArrow}
              alt='backArrow'
            />
            <img
              className={currentIndex === collectionData.length - 1 ? classes.inactive : ""}
              onClick={() => {
                setFadeOut(true);
                setTimeout(() => {
                  setFadeOut(false);
                  setCurrentIndex({ action: 'increment' });
                }, 300);
              }}
              src={ForwardArrow}
              alt='forwardArrow'
            />
          </div>
        </div>
        <div className={classes.headerImageContainer}>
          <div className={classes.headerTextContainer}>
            <div className={`${classes.headerTitle} ${classes[textColorClass]}`}>
              <h1>{title}</h1>
            </div>
            <div
              key={`header-subtitle-${Math.random()}`}
              className={`${classes.headerSubtitle} ${
                isFadeOut ? classes.fadeOut : classes.fadeIn
              }`}
            >
              {collectionData[currentIndex].subtitle}
            </div>
          </div>
          <img
            key={`banner-image-${Math.random()}`}
            className={isFadeOut ? classes.fadeOut : classes.fadeIn}
            src={collectionData[currentIndex].image}
            alt='bannerImage'
          />
        </div>
      </div>
    </div>
  );
};

export default PainPointsSolutionsHeader;
