import React from 'react';
import classes from '../../assets/css/components/MenuStoryBanner.module.scss';

const MenuStoryBanner = ({ title, imageSrc, backgroundColor, onBannerClick, animation }) => {
  return (
    <div
      data-testid='main-component'
      className={`${classes.storyBanner} ${classes[animation]}`}
      style={{ background: backgroundColor }}
    >
      <div onClick={onBannerClick} className={classes.bannerContainer}>
        <div className={classes.bannerTitle}>
          <span>{title}</span>
        </div>
        <img src={imageSrc} alt='bannerIcon' />
      </div>
    </div>
  );
};

export default MenuStoryBanner;
