import React from 'react';
import classes from '../../assets/css/components/SecondaryMenuItem.module.scss';
import buttonPrefix from '../../assets/images/Button_prefix_arrow.svg';


const SecondaryMenuItem = (props) => {
  return (
  
    <div
      data-testid='main-component'
      className={classes.secondaryMenuItem}
      style={{ backgroundColor: `${props.bgColor}` }}
      onClick={props.onMenuItemClick}
    >
      <img
        className={props.isPPL ? classes.menuIconPPL : classes.menuIcon}
        src={props.menu.icon}
        alt='logo'
      />
      <div className={classes.menuDetails}>
        <span className={classes.title}>{props.menu.title}</span>
        <span className={classes.description}>{props.menu.description}</span>
        <span className={classes.link} onClick={props.onClick}>
          <img src={buttonPrefix} alt='learnmore' />
          {props.menu.ctaText}
        </span>
      </div>
    </div>
   
  );
};

export default SecondaryMenuItem;
