import React, { useEffect, useState } from 'react';
import { Grid, Button } from '@mui/material';
import Homeicon from '../../assets/images/Homeicon.svg';
import '../../assets/css/components/FasterClaim.scss';
import { getMethod } from '../../services/commonServices';
import { useNavigate } from 'react-router-dom';
import { DeliveredInProgressAPI } from '../../utils/apiConstant';

export const FasterClaims = () => {
  const navigate = useNavigate();
  const [Claimsresponse, setClaimsresponse] = useState();
  const [isExit, setExit] = useState(false);
  const urlParams = decodeURIComponent(
    window.location.href.slice(window.location.href.indexOf('?') + 1),
  );

  const getFasterClaimsresponse = async () => {
    const response = await getMethod(DeliveredInProgressAPI);
    getDeliveredData(response);
  };

  const getDeliveredData = (values) => {
    const dataVal = values.sections.map((item) =>
      item.items.map((value) => {
        return value.items.map((level2) => {
          return level2.section;
        });
      }),
    );
    const filteredValue = dataVal.flat(Infinity).filter((data) => data.title.includes(urlParams));
    setClaimsresponse(...filteredValue);
  };

  useEffect(() => {
    getFasterClaimsresponse();
  }, []);
  const description =
    Claimsresponse &&
    Claimsresponse.description.split(/\n\n/).map((desc, index) => {
      if (desc.includes('- ')) {
        const listdata = desc.split('-');
        listdata.shift();
        return listdata.map((item, index) => {
          return (
            <div
              key={index}
              className='lloyds_button lloyds_button_two_medium lloyds-text-white lloyds-text-start pr-12'
            >
              <ul className='m-0 ml-1'>
                <li>{item}</li>
              </ul>
            </div>
          );
        });
      } else {
        return (
          <p
            className='lloyds_button lloyds_button_two_medium lloyds-text-white2 lloyds-text-start pr-12 mt-2 mb-2'
            key={index}
          >
            {desc}
          </p>
        );
      }
    });
  return (
    <div
      className={` ${isExit ? 'exitAnimation' : 'entryAnimation'} page-scroll`}
      data-testid='faster-claims'
    >
      {Claimsresponse && (
        <>
          <div className='ml-5 mr-5 mt-2 mb-3'>
            <Grid container>
              <Button size='large'>
                <img
                  src={Homeicon}
                  className='home-button mb-2'
                  onClick={() => {
                    setExit(true);
                    setTimeout(() => {
                      setExit(false);
                      navigate(-1);
                    }, 300);
                  }}
                />
              </Button>
              <Grid container>
                <h1 className='lloyds-text-white lloyds-text-start'> {Claimsresponse.title}</h1>
                <div>{description}</div>
                <Grid item xl={3} lg={3} md={3}>
                  <Grid container className='box-bg'>
                    <div className='d-flex'>
                      <img src={Claimsresponse.icon} className='home-img-size' />
                      <p className='lloyds-text-white lloyds_body lloyds_body_large m-0 lloyds-text-start pr-1'>
                        Scan to learn more
                      </p>
                    </div>

                    <Button className='lloyds-text-start ml-\.5'>
                      {' '}
                      <img src={Claimsresponse.scanningImage} className='scan-img-size' />
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
          <Grid container>
            <img
              src={Claimsresponse.mainImage}
              className={
                Claimsresponse.title === 'Our delegated authority strategy'
                  ? 'main-img-prog'
                  : 'main-img-size'
              }
            />
          </Grid>
        </>
      )}
    </div>
  );
};
