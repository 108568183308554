import React from 'react';
import classes from '../../assets/css/components/RightMilestone.module.scss';
import CurveLine from '../../assets/images/CurveLine.svg';
import { lineBreak } from '../../utils/lineBreak';

function RightCurveMilestone({
  title,
  image,
  index,
  isEnd,
  mainIndex,
  inActive,
  page,
  description,
}) {
  return (
    <div
      data-testid='right-curve-milestone'
      className={classes.mainContainer}
      style={{
        paddingTop:
          index === 0 ? '0rem' : mainIndex === 0 && index === 1 ? '12.115rem' : '24.25rem',
        marginTop: index === 0 ? '-0.56rem' : '0rem',
      }}
    >
      <div className={classes.bgContainer}>
        <div className={classes.bgLineContainer}>
          <img className={classes.bgLine} src={CurveLine} style={{ transform: 'rotate(180deg)' }} />
        </div>
        {!isEnd && (
          <div className={classes.bgLineContainer}>
            <img className={classes.bgLine} src={CurveLine} style={{ transform: 'scaleX(-1)' }} />
          </div>
        )}
      </div>
      <div
        className={classes.contentContainer}
        style={{
          bottom: (page === 'painPoints' || page === 'solutions') && !inActive ? '1rem' : '4rem',
          opacity: inActive ? '0.4' : '1',
        }}
      >
        <div className={classes.imageContainer}>
          <img className={classes.contentImage} src={image} />
        </div>
        {(page === 'painPoints' || page === 'solutions') && !inActive ? (
          <div
            className={
              page === 'painPoints' ? classes.painPointsContainer : classes.solutionsContainer
            }
            style={mainIndex === 3 || mainIndex === 4 ? { marginTop: '-4.4rem' } : {}}
          >
            <div className={classes.title}>{lineBreak(title)}</div>
            <div className={classes.description}>{lineBreak(description)}</div>
          </div>
        ) : (
          <div
            className={classes.contentTitle}
            style={mainIndex === 3 || mainIndex === 4 ? { marginTop: '-4.4rem' } : {}}
          >
            {lineBreak(title)}
          </div>
        )}
      </div>
    </div>
  );
}

export default RightCurveMilestone;
