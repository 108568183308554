import React from 'react';
import '../../assets/css/pages/JourneyPage.scss';
import JourneySection from './JourneySection';

const JourneyBody = ({
    journeyResponse,
    page,
    collectionData,
}) => {
  return (
  <div data-testid='journeyBody-component' style={{overflow: "hidden"}}>
    {journeyResponse.content.journey.map((content, index) => {
      return(
        <JourneySection
            key={content.journeyId}
            journeyId={content.journeyId}
            subTitle={content.subTitle}
            title={content.title}
            milestones={content.milestones}
            journey={journeyResponse.content.journey}
            mainIndex={index}
            page={page}
            collectionData={collectionData}
            category={journeyResponse.category}
            subCategory={journeyResponse.subCategory}
        />
      )
    })}
  </div>
  );
};

export default JourneyBody;
