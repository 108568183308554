import React from 'react';

import classes from '../../assets/css/components/FindOutMoreCard.module.scss';

function FindOutMoreCard({ icon, title, description, qrImage, backgroundColor }) {
  return (
    <div className={classes.cardContainer} style={{ backgroundColor: backgroundColor }} data-testid='find-out-more-card'>
      <img className={classes.cardIcon} src={icon} alt='cardIcon' />

      <div className={classes.cardTitle}>
        {title.toLowerCase() == 'ask us a question!' ? (
          <div>
            Ask us <br /> a question!
          </div>
        ) : (
          title
        )}
      </div>
      <div className={classes.cardDescription}>{description}</div>
      <img className={classes.cardQRImage} src={qrImage} alt='cardIcon' />
    </div>
  );
}

export default FindOutMoreCard;
