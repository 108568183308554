import React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { styled } from '@mui/material/styles';

function DrawerFilterTab({filters, onChangeHandler, value}) {

    const CustomTab = styled((props) => <Tab disableRipple wrapped {...props} />)(
        () => ({
          textTransform: 'none',
          fontFamily: "NHaasGroteskTXPro-55Rg",
          fontSize: "1.5rem",
          fontWeight: "400",
          padding: "0.75rem 1.25rem",
          border: "0.25rem solid #fff",
          color: "#161616",
          margin: "0rem 0.1rem",
          maxWidth: "20rem",
          '&.Mui-selected': {
            color: "#161616",
            border: "0.25rem solid #00cbb6",
            borderRadius: "0.5rem",
            backgroundColor: "rgba(0, 203, 182, 0.20)",
          },
        }),
      );
    
      return (
        <Tabs indicatorColor="none" sx={{".MuiTabs-flexContainer": {justifyContent: "space-between"}, padding: "0rem 0.7rem"}} value={value} onChange={(_, value) => onChangeHandler(value)} data-testid='drawer-filter-tab'>
          {filters.map((filter) => (
            <CustomTab key={filter} value={filter} label={filter} />
          ))}
        </Tabs>
      )
}

export default DrawerFilterTab