import React, { useEffect, useContext } from 'react';
import BackArrow from '../../assets/images/Back_Arrow.svg';
import ForwardArrow from '../../assets/images/Forward_Arrow.svg';
import Home from '../../assets/images/home_white.svg';
import Replay from '../../assets/images/replay.svg';
import classes from '../../assets/css/components/StoryHeader.module.scss';
import MenuItem from '../../components/MenuItems/MenuItem';
import MenuButtons from '../../components/MenuButtons/MenuButtons';
import { getMethod } from '../../services/commonServices';
import { storyContext } from '../../context/StoryContextProvider';
import { StoryAPI, MenuAPI } from '../../utils/apiConstant';
import { useNavigate } from 'react-router-dom';

const StoryPage = () => {
  const { state, setCurrentIndex, setStoryResponse } = useContext(storyContext);
  const navigate = useNavigate();
  const urlParams = window.location.href.slice(window.location.href.indexOf('?') + 1);
  const selectedNavData = state.menuResponse?.menuItems?.find(
    (item) => item.name.toLowerCase() === state.storyResponse.subtitle.toLowerCase(),
  );
  const getStoryResponse = async () => {
    const response = await getMethod(StoryAPI + urlParams);

    response.collection.unshift({
      image: response.image,
      textCollection: [
        {
          text: response.subtitle,
        },
        {
          startText: response.startText,
        },
      ],
    });
    const Menuresponse = await getMethod(MenuAPI);
    setStoryResponse({ response, menuResponse: Menuresponse, locationSearch: urlParams });
  };

  useEffect(() => {
    getStoryResponse();
  }, []);

  const buttonList = [
    {
      title: 'Replay',
      icon: Replay,
      onClick: () => setCurrentIndex({ action: 'replay' }),
    },
  ];
  return (
    <div
      data-testid='story-page'
      style={{
        backgroundColor:
          state.currentIndex === 0
            ? state?.storyResponse?.category === 'Placing Risk'
              ? '#00ad9b'
              : '#54a6ff'
            : '',
        height: '100%',
        zIndex: '-2',
      }}
    >
      <div
        className={`${classes.storyPageButtonRight} ${
          state.currentIndex === state.storyResponse?.collection.length - 1
            ? classes.pageButtonInactive
            : ''
        }`}
        onClick={() => {
          setTimeout(() => {
            setCurrentIndex({ action: 'increment' });
          }, 300);
        }}
      ></div>
      <div
        className={`${classes.storyPageButtonLeft} ${
          state.currentIndex === 0 ? classes.pageButtonInactive : ''
        }`}
        onClick={() => {
          setTimeout(() => {
            setCurrentIndex({ action: 'decrement' });
          }, 300);
        }}
      ></div>
      {state.storyResponse && (
        <div className={classes.storypage}>
          <div data-testid='Header-component' className={classes.headerBanner}>
            <div className={classes.headerContainer}>
              <div className={classes.headerIcon}>
                <div className={classes.headerCircleContainer}>
                  {state.storyResponse.collection.map(
                    (_, index) =>
                      index !== 0 && (
                        <div
                          key={`header-circle-${index}`}
                          className={` ${classes.headerCircle} ${
                            state.locationSearch === 'category=PlacingRisk'
                              ? classes.placingheaderCircle
                              : classes.payingheaderCircle
                          } ${
                            state.currentIndex !== 0 &&
                            index <= state.currentIndex &&
                            classes.headerCircleActive
                          }`}
                        />
                      ),
                  )}
                </div>
              </div>
              <div className={classes.headerImageContainer}>
                <div>
                  <img
                    onClick={() => {
                      setTimeout(() => {
                        navigate(-1);
                      }, 300);
                    }}
                    src={Home}
                    alt='bannerIcon'
                  />
                </div>
                <div className={classes.headerArrowContainer}>
                  <img
                    className={state.currentIndex === 0 && classes.inactive}
                    onClick={() => {
                      setTimeout(() => {
                        setCurrentIndex({ action: 'decrement' });
                      }, 300);
                    }}
                    src={BackArrow}
                    alt='backArrow'
                  />
                  <img
                    className={
                      state.currentIndex === state.storyResponse.collection.length - 1 &&
                      classes.inactive
                    }
                    onClick={() => {
                      setTimeout(() => {
                        setCurrentIndex({ action: 'increment' });
                      }, 300);
                    }}
                    src={ForwardArrow}
                    alt='forwardArrow'
                  />
                </div>
              </div>

              <div
                className={
                  state.currentIndex !== state.storyResponse.collection.length - 1
                    ? classes.storytext
                    : ''
                }
              >
                {state.storyResponse.collection[state.currentIndex].textCollection.map(
                  (value, index) => {
                    return state.currentIndex === 0 ? (
                      <div className={classes.storyTitle}>
                        <h1 className='lloyds-text-white2'>{value.text}</h1>
                        <h5 className={`lloyds-text-white2 ${classes.titleCapitalize}`}>
                          {value.startText}
                        </h5>
                      </div>
                    ) : value.text && value.text.includes(':') ? (
                      <div
                        key={index}
                        className={`${
                          value.highlight === true
                            ? state.storyResponse.category === 'Placing Risk'
                              ? 'lloyds-text-storyblue'
                              : 'lloyds-text-accentblue'
                            : 'lloyds-text-white2'
                        } mb-2`}
                      >
                        {value.text}
                        <br />
                      </div>
                    ) : (
                      <span
                        key={index}
                        className={
                          value.highlight === true
                            ? state.storyResponse.category === 'Placing Risk'
                              ? 'lloyds-text-storyblue'
                              : 'lloyds-text-accentblue'
                            : 'lloyds-text-white2'
                        }
                      >
                        {value.text}
                      </span>
                    );
                  },
                )}
              </div>
              {state.currentIndex === state.storyResponse.collection.length - 1 ? (
                <div style={{ zIndex: '2' }}>
                  {selectedNavData.collections.map((collection, index) => {
                    return (
                      (collection.title === 'In the open market' ||
                        collection.title === 'As a delegated authority') && (
                        <div key={index}>
                          <div className={classes.menustory}>
                            <div className={classes.menutext}>
                              <span className={` lloyds-text-white2 pb-1.5`}>
                                {' '}
                                {collection.title === 'In the open market'
                                  ? `Learn more about ${state.storyResponse.category.toLowerCase()} in the open market`
                                  : `Learn more about ${state.storyResponse.category.toLowerCase()} as a delegated authority`}
                              </span>
                            </div>
                          </div>

                          <div className={'collection-items-container mb-4'}>
                            {collection.items.map((item, index) => {
                              return (
                                <MenuItem
                                  title={item.title}
                                  icon={item.icon}
                                  isActive={item.isActive}
                                  key={`collection-item-${index}`}
                                  bgColor={item.backgroundColour}
                                  onMenuItemClick={() =>
                                    item.isActive &&
                                    navigate(
                                      `${
                                        item.title === 'Journey Overview'
                                          ? '/journey'
                                          : '/detailedsteps'
                                      }?category=${selectedNavData.menuItemId}&subcategory=${
                                        collection.title
                                      }`,
                                    )
                                  }
                                />
                              );
                            })}
                          </div>
                        </div>
                      )
                    );
                  })}
                  <MenuButtons buttonList={buttonList} backgroundColor={'204, 228,247, 1'} />
                </div>
              ) : (
                ''
              )}
            </div>
          </div>

          <div className={state.currentIndex === 0 ? classes.staticstory : classes.imageConatiner}>
            <img
              key={`banner-image-${Math.random()}`}
              src={state.storyResponse.collection[state.currentIndex].image}
              alt='bannerImage'
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default StoryPage;
