// eslint-disable-next-line no-undef
const url = process.env.REACT_APP_URL;

export const ScreensaverAPI = url + 'api/Screensaver';
export const GlossaryAPI = url + 'api/Glossary';
export const MenuAPI = url + 'api/Menu';
export const StoryAPI = url + 'api/Story?';
export const JourneyAPI = url + 'api/Journey?';
export const DetailedStepsAPI = url + 'api/DetailedSteps';
export const DeliveredInProgressAPI = url + 'api/DeliveredInProgress';
export const PplAPI = url + 'api/Ppl';
export const FindoutAPI = url + 'api/FindOutMore';
