import React from 'react';
import backgroundImage from '../../assets/images/PplTimeline.png';
import classes from '../../assets/css/components/PplBody.module.scss';
const PplBody = ({ collection, image }) => {
  const style = {
    backgroundImage: `url(${backgroundImage})`,
    backgroundRepeat: ' no-repeat',
    backgroundPosition: '9.12rem 19.62rem',
    backgroundSize: '48.875rem',
  };
  return (
    <div style={style} className='pplBody' data-testid='ppl-body'>
      {collection?.map((item, itemIndex) => (
        <div
          key={`ppl-collection-${itemIndex}`}
          className={`${classes.backgroundSectionColor}`}
          style={itemIndex === 0 ? { paddingTop: '6.25rem' } : {}}
        >
          <div className={classes.sectionTitle}>
            <div className={classes.sectionText}>{item.title}</div>
            {itemIndex === 0 && <img src={image} />}
          </div>
          <div className={classes.collectionItemsContainer}>
            {item.collectionItems.map((collectionItem, index) => {
              const isRight =
                item.collectionItems[index + 1]?.cssClass[0] === 'right'
                  ? item.collectionItems[index + 1]
                  : item.collectionItems[index + 2];
              return (
                <div
                  key={`collection-item-${index}`}
                  className={classes.itemContainer}
                  style={{
                    justifyContent:
                      collectionItem.cssClass[0] === 'left' && isRight?.cssClass[0] === 'right'
                        ? 'space-between'
                        : collectionItem.cssClass[0].includes('center', 0)
                        ? 'center'
                        : collectionItem.cssClass[0] === 'left'
                        ? 'flex-start'
                        : 'flex-end',
                    paddingBottom: itemIndex === collection.length - 1 && '27.75rem',
                  }}
                >
                  {collectionItem.cssClass[0] !== 'right' && (
                    <div className={classes.collectionItem}>
                      <img src={`${item.collectionItems[index].image}`} />
                      {collectionItem.description && (
                        <div className={classes.collectionItemText}>
                          {collectionItem.description}
                        </div>
                      )}
                    </div>
                  )}
                  {collectionItem.cssClass[0] === 'left' && isRight?.cssClass[0] === 'right' && (
                    <div className={classes.collectionItem}>
                      <img
                        src={`${
                          item.collectionItems[index + 1]?.cssClass[0] === 'right'
                            ? item.collectionItems[index + 1].image
                            : item.collectionItems[index + 2].image
                        }`}
                      />
                      {isRight.description && (
                        <div className={classes.collectionItemText}>{isRight.description}</div>
                      )}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      ))}
    </div>
  );
};

export default PplBody;
