import React from 'react';
import DetailedStepsButton from './DetailedStepsButton';
import DetailedStepsCard from './DetailedStepsCard';
import classes from '../../assets/css/components/DetailedStepsDetailCard.module.scss';
import line from '../../assets/images/DetailedStepsLine.svg';
import straightLine from '../../assets/images/DetailedStepsStraightLine.svg';
import arrow from '../../assets/images/Button_prefix_arrow_dark.svg';

import { Grid } from '@mui/material';
import { lineBreak } from '../../utils/lineBreak';

function DetailedStepsDetailCard({
  title,
  backgroundColor,
  buttonColor,
  buttonIcon,
  buttonName,
  onButtonClick,
  cards,
  isActive,
  bottomFlowLine,
  onCardButtonClick,
}) {
  const FlowLine = ({ className, imgUrl, bottom }) => {
    return (
      <div className={className}>
        <img src={imgUrl} style={bottom && { transform: 'scaleX(-1)' }} />
      </div>
    );
  };

  return (
    <div data-testid='detailed-steps-detail-card'>
      {cards[0] ? (
        <div
          className={classes.detailCardContainer}
          style={isActive ? { opacity: '1' } : { opacity: '0.4' }}
        >
          <div
            className={classes.detailCardHeader}
            style={{ backgroundColor: backgroundColor }}
          >
            <Grid container spacing="2rem" alignItems='center' padding='0rem 0.4rem'>
              <Grid item xs={9}>
                <div className={classes.detailCardHeaderTitleContainer}>
                  <span>{lineBreak(title)}</span>
                </div>
              </Grid>
              <Grid item xs={3} display='flex' justifyContent='flex-end'>
                {buttonName && (
                  <DetailedStepsButton
                    backgroundColor={buttonColor}
                    iconUrl={buttonIcon}
                    onClick={isActive ? onButtonClick : () => {}}
                  >
                    {buttonName}
                  </DetailedStepsButton>
                )}
              </Grid>
            </Grid>
          </div>
          {cards[0] && <div
            className={classes.detailCardBody}
            style={{
              borderRight: `0.25rem solid ${backgroundColor}`,
              borderBottom: `0.25rem solid ${backgroundColor}`,
              borderLeft: `0.25rem solid ${backgroundColor}`,
              paddingBottom: !bottomFlowLine ? "2.187rem" : "0rem",
            }}
          >
            <FlowLine className={classes.flowLine} imgUrl={line} />
            {cards.map((card, index) => (
              <div key={`${card.buttonId}-${index}`}>
                <DetailedStepsCard
                  backgroundColor={"#4BC7B7"}
                  cardIcon={card.image}
                  description={card.description}
                  isActive={card.buttonId}
                  buttonColor={"#CCE4F7"}
                  buttonIcon={arrow}
                  buttonName={card.buttonText}
                  onCardButtonClick={isActive ? () => onCardButtonClick(card.buttonId) : () => {}}
                />
                {index !== cards.length - 1 && <FlowLine className={classes.straightLine} imgUrl={straightLine} />}
              </div>
            ))}
            {bottomFlowLine && <FlowLine className={classes.flowLine} imgUrl={line} bottom />}
          </div>}
          {bottomFlowLine && <FlowLine className={classes.cardBottomFlow} imgUrl={straightLine} />}
        </div>
      ) : (
        <div className={classes.cardlessDetailCardContainer}>
          <div className={classes.cardlessHeader}>
            <div className={classes.detailCardHeaderTitleContainer}>
              <span>{title}</span>
            </div>
          </div>
          {bottomFlowLine && <FlowLine className={classes.cardBottomFlow} imgUrl={straightLine} />}
        </div>
      )}
    </div>
  );
}

export default DetailedStepsDetailCard;
