import React, { createContext, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

const initialState = {
  journeyResponse: null,
  painPoints: null,
  solutions: null,
  currentTab: null,
  currentIndex: 0,
  locationSearch: null,
};
export const journeyContext = createContext({
  state: initialState,
});

const JourneyContextProvider = () => {
  const navigate = useNavigate();
  const [state, setState] = useState(initialState);
  const setJourneyResponse = ({ response, locationSearch }) => {
    setState({
      ...initialState,
      journeyResponse: response,
      painPoints: response.content.painPoints,
      solutions: response.content.solutions,
      locationSearch: locationSearch,
    });
  };
  const setCurrentTab = (response) => {
    setState({
      ...state,
      currentTab: response,
    });
    navigate(`journey/painpointsolutions`);
  };
  const setCurrentIndex = ({ action }) => {
    if (action === 'increment') {
      setState({
        ...state,
        currentIndex: state.currentIndex + 1,
      });
    } else if (action === 'decrement') {
      setState({
        ...state,
        currentIndex: state.currentIndex - 1,
      });
    }
  };

  return (
    <journeyContext.Provider
      value={{
        state,
        setJourneyResponse,
        setCurrentTab,
        setCurrentIndex,
      }}
    >
      <Outlet />
    </journeyContext.Provider>
  );
};

export default JourneyContextProvider;
