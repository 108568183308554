import React, { useState } from 'react';
import Modal from '@mui/material/Modal';
import ForwardArrow from '../../assets/images/Forward_Arrow_Black.svg';
import ForwardArrowWhite from '../../assets/images/Forward_Arrow.svg';
import DataFirst from '../../assets/images/DataFirst_Black.svg';
import DocumentFirst from '../../assets/images/DocumentFirst_Black.svg';
import '../../assets/css/components/DetailedStepsModal.scss';

const DetailedStepsModal = ({ open, setOpen, setDataDocumentFilter }) => {
  const [expand, setExpand] = useState(false);
  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
      data-testid='detailed-steps-modal'
    >
      <div className='detailed-steps-modal'>
        <div className='modal-title'>How do you place risk in the open market?</div>
        <div className='modal-subtitle'>
          The target state for Blueprint Two is a data-first approach, however, it is recognised
          firms will be transitioning to the new services at different speeds. To accommodate this
          there is a document first option available in the short-term.
        </div>
        <div className='selection-container'>
          <div className='selection'>
            <div className='document-first-logo'>
              <img src={DocumentFirst} alt='document-first-logo' />
            </div>
            <div className='selection-title'>Document first</div>
            <div className='selection-subtitle'>
              Where documents are used to facilitate Core Data Record (CDR) assembly and submission
              e.g. the Market Reform Contract (MRC).
            </div>
            <div
              className='document-first-button'
              onClick={() => {
                setDataDocumentFilter('Document First');
                setOpen(false);
              }}
            >
              <img src={ForwardArrowWhite} alt='select-button-logo' />
              <div className='document-first-button-title'>Select</div>
            </div>
          </div>
          <div className='selection'>
            <div className='data-first-logo'>
              <img src={DataFirst} alt='data-first-logo' />
            </div>
            <div className='selection-title'>Data first</div>
            <div className='selection-subtitle'>
              Where system structured data is used to facilitate Core Data Record (CDR) assembly and
              submission.
            </div>
            <div
              className='data-first-button'
              onClick={() => {
                setDataDocumentFilter('Data First');
                setOpen(false);
              }}
            >
              <img src={ForwardArrow} alt='select-button-logo' />
              <div className='data-first-button-title'>Select</div>
            </div>
          </div>
        </div>
        <div className='modal-subtitle-container' onClick={() => setExpand(!expand)}>
          <div>How to get from document to data first?</div>
          <img src={ForwardArrow} alt='expand-arrow' />
        </div>
        <div className={`modal-description-container${expand ? '' : '-inactive'}`}>
          <div>
            To move to a data first model, speak to your technology team internally, and/or your
            technology partner. Typically, they would look at the following changes to support a
            data first model:
          </div>
          <ol>
            <li>
              Enable a broader set of data to be captured at the beginning of customer interactions
              in Customer Relationship Management (CRM) systems and Policy Administration Systems
              (PAS)
            </li>
            <li>
              Use this data to assemble and submit a CDR, from an accredited placing platform. If
              technology permits, this data could also be used to populate a MRC template
            </li>
            <li>
              Interlink with accredited placing platforms to trade via Application Programming
              Interfaces (APIs)
            </li>
          </ol>
        </div>
      </div>
    </Modal>
  );
};

export default DetailedStepsModal;
