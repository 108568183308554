import React from 'react';
import classes from '../../assets/css/components/DetailedStepsFilterTab.module.scss';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { styled } from '@mui/material/styles';

function DetailedStepsFilterTab({ filters, onChangeHandler, value }) {
  const CustomLabel = ({ iconColor, text }) => {
    return (
      <div className={classes.labelContainer}>
        {iconColor && (
          <div className={classes.labelIcon} style={{ backgroundColor: iconColor }}></div>
        )}
        <span className={classes.labelText}>{text}</span>
      </div>
    );
  };

  const CustomTab = styled((props) => <Tab disableRipple wrapped {...props} />)(() => ({
    textTransform: 'none',
    marginRight: '0.937rem',
    fontFamily: 'NHaasGroteskTXPro-55Rg',
    color: '#1890ff',
    borderRadius: '0.625rem',
    padding: '0.937rem 1.437rem',
    maxWidth: '20rem',
    '&.Mui-selected': {
      color: '#1890ff',
      border: '0.187rem solid #cce4f7',
      borderRadius: '0.625rem',
      padding: '0.75rem 1.25rem',
      backgroundColor: 'rgba(204, 228, 247, 0.10)',
    },
  }));

  return (
    <Tabs
      indicatorColor='none'
      value={value}
      onChange={(_, value) => onChangeHandler(value)}
      data-testid='detailed-steps-filter-tab'
    >
      {filters.map((filter, index) => (
        <CustomTab
          key={filter}
          value={filter}
          label={
            <CustomLabel
              iconColor={index === 1 ? '#54a6ff' : index === 2 ? '#ffbb54' : ''}
              text={filter}
            />
          }
        />
      ))}
    </Tabs>
  );
}

export default DetailedStepsFilterTab;
