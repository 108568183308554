import React from 'react';
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import classes from '../../assets/css/components/Drawer.module.scss';
import CloseIcon from '../../assets/images/PopUpClose.svg';
import { lineBreak } from '../../utils/lineBreak';

const DrawerComponent = ({
  title,
  description,
  open,
  image,
  flagColor,
  content,
  onBannerClick,
}) => {
  return (
      <Drawer
        PaperProps={{
          sx: { width: '80%', borderRadius: '1.875rem 0rem 0rem 1.875rem', backgroundColor: '#fafafa' },
        }}
        anchor={'right'}
        open={open}
        onClose={onBannerClick}
        data-testid='drawer-component'
      >
        <img src={CloseIcon} className={classes.closeIcon} onClick={onBannerClick} />
        <div className={classes.drawerContainer}>
          <div
            className={classes.imageContainer}
            style={{ height: content ? '10.3rem' : '18.75rem' }}
          >
            <img src={image} alt='popup-image' />
          </div>
          <div className={classes.textContainer}>
            <Divider sx={{ border: `0.5rem solid ${content ? "#4bc7b7" : flagColor}` }} orientation='vertical' flexItem />
            <div className={classes.textContent}>
              <div className={classes.title}>{lineBreak(title)}</div>
              <div className={classes.description}>{lineBreak(description)}</div>
            </div>
          </div>
        </div>
        {content && <div className={classes.content}>{content}</div>}
      </Drawer>
  );
};

export default DrawerComponent;
