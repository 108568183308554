import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { getMethod } from '../../services/commonServices';
import { GlossaryAPI } from '../../utils/apiConstant';
import Home from '../../assets/images/home_white.svg';
import JourneyHeader from '../../components/JourneyHeader/JourneyHeader';
import '../../assets/css/pages/Glossary.scss';
import { useNavigate } from 'react-router';

const Glossary = () => {
  const [glossary, setGlossary] = useState({});
  const [isExit, setExit] = useState(false);
  const navigate = useNavigate();

  const getGlossaryResponse = async () => {
    const response = await getMethod(GlossaryAPI);
    setGlossary(response);
  };

  useEffect(() => {
    getGlossaryResponse();
  }, []);

  return (
    <div
      className={` ${isExit ? 'exitAnimation' : 'entryAnimation'} page-scroll`}
      data-testid='glossary'
    >
      <JourneyHeader
        imageSrc={Home}
        title={glossary.title}
        style={{ paddingBottom: '0' }}
        onHomeClick={() => {
          setExit(true);
          setTimeout(() => {
            setExit(false);
            navigate("/");
          }, 300);
        }}
      />
      <div className="glossary-body">
        {glossary.sections?.map((section, index) => {
          return(
            <Grid 
              container 
              key={index} 
              className="main-container" 
              style={{borderBottom: index !== glossary.sections?.length-1 ? "0.125rem solid #fafafa" : "none"}}
            >
              <Grid item xs={3}>
                <div className="title-container">
                  {section.title}
                </div>
              </Grid>
              <Grid item xs={4}>
                <div className="description-container">
                  {section.subtitle}
                </div>
              </Grid>
              <Grid item xs={5}>
                <div className="description-container left-space">
                  {section.description}
                </div>
              </Grid>
            </Grid>
          )
        })}
      </div>
    </div>
  );
};

export default Glossary;
