import React from 'react';
import classes from '../../assets/css/components/PplHeader.module.scss';
import PplHomeIcon from '../../assets/images/PPLHomeIcon.svg';
import PplHeaderTabs from './PplHeaderTabs';
const PplHeader = ({ title, onHomeClick, backgroundColor, pplTabs }) => {
  return (
    <>
      <div
        className={classes.headerContainer}
        style={{ background: backgroundColor }}
        data-testid='ppl-header'
      >
        <div className={classes.homeIconContainer}>
          <img onClick={onHomeClick} src={PplHomeIcon} alt='homeIcon' />
        </div>
        <div className={classes.titleContainer}>
          <h1>{title}</h1>
        </div>
        <PplHeaderTabs tabs={pplTabs} />
      </div>
      <hr />
    </>
  );
};

export default PplHeader;
