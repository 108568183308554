import React from 'react';
import MenuButtons from '../MenuButtons/MenuButtons';
import classes from '../../assets/css/components/JourneyHeader.module.scss';

const JourneyHeader = ({ title, imageSrc, backgroundColor, buttonList, onHomeClick, style }) => {
  return (
    <div
      data-testid='Header-component'
      className={classes.headerBanner}
      style={style ? { ...style, background: backgroundColor } : { background: backgroundColor }}
    >
      <div className={classes.headerContainer}>
        <div className={classes.headerIcon}>
          <img
            onClick={onHomeClick}
            src={imageSrc}
            alt='bannerIcon'
          />
        </div>
        <div className={classes.headerTitle}>
          <h1>{title}</h1>
          <MenuButtons buttonList={buttonList} backgroundColor={'40, 47, 84, 0.95'} />
        </div>
      </div>
    </div>
  );
};

export default JourneyHeader;
