import React, { createContext, useState } from 'react';
import { Outlet } from 'react-router-dom';

const initialState = {
  storyResponse: null,
  menuResponse: null,
  currentIndex: 0,
  locationSearch: null,
};
export const storyContext = createContext({
  state: initialState,
});

const StoryContextProvider = () => {
  const [state, setState] = useState(initialState);
  const setStoryResponse = ({ response, menuResponse, locationSearch }) => {
    setState({
      ...initialState,
      storyResponse: response,
      menuResponse: menuResponse,
      locationSearch: locationSearch,
    });
  };
  const setMenuResponse = ({ response }) => {
    setState({
      ...initialState,
      menuResponse: response,
    });
  };
  const setCurrentIndex = ({ action }) => {
    if (action === 'increment') {
      setState({
        ...state,
        currentIndex: state.currentIndex + 1,
      });
    } else if (action === 'decrement') {
      setState({
        ...state,
        currentIndex: state.currentIndex - 1,
      });
    } else if (action === 'replay') {
      setState({
        ...state,
        currentIndex: 0,
      });
    }
  };

  return (
    <storyContext.Provider
      value={{
        state,
        setStoryResponse,
        setCurrentIndex,
        setMenuResponse,
      }}
    >
      <Outlet />
    </storyContext.Provider>
  );
};

export default StoryContextProvider;
