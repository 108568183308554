import React from 'react';
import '../../assets/css/components/MenuNav.scss';

const MenuNav = ({ items, currentTab, setCurrentTab, setStoryAnimation }) => {
  return (
    <div className='wrapper' data-testid='nav-component'>
      <div className='nav-scroll' id='nav-scroll'>
        <div className='nav-bar ml-5 mb-2'>
          {items?.map((item, index) => (
            <div
              key={`menu-nav-${index}-${Math.random()}`}
              className={`nav-item NHaasGroteskTXPro-75Bd ${
                currentTab === item.menuItemId ? 'active' : 'inactive'
              }`}
              onClick={() => {
                setStoryAnimation('banner-fade-out');
                //document.getElementById('nav-scroll').scrollLeft = 0; /*Reset scroll position to default*/
                setTimeout(() => {
                  setCurrentTab({ currentTab: item.menuItemId });
                  setStoryAnimation('banner-fade-in');
                }, 300);
              }}
            >
              {item.name}
            </div>
          ))}
        </div>
      </div>
      <hr />
    </div>
  );
};

export default MenuNav;
