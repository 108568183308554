import React from 'react';
import classes from '../../assets/css/components/DetailedStepsHeader.module.scss';
import MenuButtons from '../MenuButtons/MenuButtons';
import DetailedStepsFilterTab from './DetailedStepsFilterTab';

function DetailedStepsHeader({
  title,
  imageSrc,
  backgroundColor,
  onHomeClick,
  filters,
  filterValue,
  onFilterChange,
  buttonName,
  buttonIcon,
  buttonBgColor,
  onButtonClick,
}) {
  return (
    <>
      <div
        className={classes.headerContainer}
        style={{ background: backgroundColor }}
        data-testid='detailed-steps-header'
      >
        <div className={classes.homeIconContainer}>
          <img onClick={onHomeClick} src={imageSrc} alt='homeIcon' />
        </div>
        <div className={classes.titleContainer}>
          <span>{title}</span>
        </div>
        <div className={classes.filterContainer}>
          <div>
            <DetailedStepsFilterTab
              filters={filters}
              value={filterValue}
              onChangeHandler={onFilterChange}
            />
          </div>
          {buttonName && (
            <MenuButtons
              buttonList={[{ title: buttonName, icon: buttonIcon, onClick: onButtonClick }]}
              backgroundColor={buttonBgColor}
            />
          )}
        </div>
      </div>
      <hr />
    </>
  );
}

export default DetailedStepsHeader;
