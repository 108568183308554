import React from 'react';
import { useNavigate } from 'react-router';
import '../../assets/css/pages/ErrorPage.scss';
import ErrorPageImage from '../../assets/images/ErrorPageImage.svg';

function ErrorPage() {
  const navigate = useNavigate();

  return (
    <div className="error-page-container" data-testid='error-page'>
        <div className="error-page-image-container">
          <img className="error-page-image" src={ErrorPageImage} alt="404-error" />
        </div>
        <div className="error-page-title">Page not found</div>
        <div className="error-page-description">{"Sorry, the page you're looking for couldn't be found."}</div>
        <div className="error-page-button" onClick={() => navigate('/')}>Back to homepage</div>
    </div>
  )
}

export default ErrorPage