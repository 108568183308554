import React from 'react';
import classes from '../../assets/css/components/LeftCurveMilestone.module.scss';
import CurveLine from '../../assets/images/CurveLine.svg';
import { lineBreak } from '../../utils/lineBreak';

function LeftCurveMilestone({
    title,
    image,
    index,
    isStart,
    isEnd,
    inActive,
    page,
    description
}) {
  return (
    <div 
        data-testid='left-curve-milestone'
        className={classes.mainContainer} 
        style={{
            paddingTop: index === 1 ? "24.25rem" : index === 0 ? "0rem" : "11.55rem",
            marginTop: index === 0 && !isStart ? "-0.56rem" : "0rem",
            }}
    >
        <div className={classes.bgContainer}>
            {!isStart && <div className={classes.bgLineContainer}>
                <img className={classes.bgLine} src={CurveLine} style={{transform: "scaleY(-1)"}} />
            </div>}
            {!isEnd && <div className={classes.bgLineContainer}>
             <img className={classes.bgLine} src={CurveLine} />
            </div>}
        </div>
        <div 
            className={classes.contentContainer}
            style={{
                bottom: isStart ? (page === "painPoints" || page === "solutions") && !inActive ? "7rem" : "12rem" : (page === "painPoints" || page === "solutions") && !inActive ? "1rem" : "4rem",
                opacity: inActive ? "0.4" : "1",
            }}
        >
            <div className={classes.imageContainer}>
                <img className={classes.contentImage} src={image} />
            </div>
            {(page === "painPoints" || page === "solutions") && !inActive ? 
                <div className={page === "painPoints" ? classes.painPointsContainer : classes.solutionsContainer}>
                    <div className={classes.title}>
                        {lineBreak(title)}
                    </div>
                    <div className={classes.description}>
                        {lineBreak(description)}
                    </div>
                </div>
                :
                <div className={classes.contentTitle}>
                    {lineBreak(title)}
                </div>}
        </div>
    </div>
  )
}

export default LeftCurveMilestone;