import React, { createContext, useState } from 'react';

const initialState = {
  menuResponse: null,
  currentTab: null,
  navItems: null,
};
export const landingPageContext = createContext({
  state: initialState,
});

const LandingPageContextProvider = (props) => {
  const [state, setState] = useState(initialState);
  const setMenuResponse = (response) => {
    setState({
      ...state,
      menuResponse: response,
      currentTab: state.currentTab ? state.currentTab : response.menuItems[0].menuItemId,
      navItems: state.navItems ? state.navItems : [...response.menuItems],
    });
  };
  const setCurrentTab = ({ currentTab }) => {
    setState({ ...state, currentTab });
  };
  return (
    <landingPageContext.Provider value={{ state, setMenuResponse, setCurrentTab }}>
      {props.children}
    </landingPageContext.Provider>
  );
};

export default LandingPageContextProvider;
