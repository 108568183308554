import React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { styled } from '@mui/material/styles';

function DrawerTab({tabNames, onChangeHandler, value}) {
    
      const CustomTab = styled((props) => <Tab disableRipple wrapped {...props} />)(
        () => ({
          textTransform: 'none',
          fontFamily: "NHaasGroteskTXPro-55Rg",
          fontSize: "1.5rem",
          fontWeight: "400",
          padding: "0.625rem 2rem",
          borderBottom: "0.125rem solid #00cbb6",
          color: "#a1a1a2",
          '&.Mui-selected': {
            color: '#161616',
            borderLeft: "0.125rem solid #00cbb6",
            borderTop: "0.125rem solid #00cbb6",
            borderRight: "0.125rem solid #00cbb6",
            borderBottom: "none",
            borderRadius: "0.312rem 0.312rem 0rem 0rem",
          },
        }),
      );
    
      return (
        <Tabs indicatorColor="none" variant="fullWidth" value={value} onChange={(_, value) => onChangeHandler(value)} data-testid='drawer-tab' sx={{padding: "0rem 5rem"}}>
          {tabNames.map((name) => (
            <CustomTab key={name} value={name} label={name} />
          ))}
        </Tabs>
      )
}

export default DrawerTab