import React, { useContext} from 'react';
import { journeyContext } from '../../context/JourneyContextProvider';
import classes from '../../assets/css/pages/PainPointsSolutions.module.scss';
import PainPointsSolutionsHeader from '../../components/PainPointsSolutionsHeader/PainPointsSolutionsHeader';
import JourneyBody from '../../components/JourneyBody/JourneyBody';
import { useNavigate } from 'react-router';

const PainPointsSolutions = () => {
  const { state, setCurrentIndex } = useContext(journeyContext);
  const navigate = useNavigate();

  return (
    <div className={classes.ppsContainer} >
      <PainPointsSolutionsHeader
        title={state[state.currentTab].title}
        backgroundColor='#161616f2'
        textColorClass={state.currentTab}
        collectionData={state[state.currentTab].collection}
        currentIndex={state.currentIndex}
        setCurrentIndex={setCurrentIndex}
        onCloseClick={() => {
          navigate(-1);
        }}
      />
      <hr />
      <JourneyBody
        journeyResponse={state.journeyResponse}
        page={state.currentTab}
        collectionData={state[state.currentTab].collection[state.currentIndex]}
      />
    </div>
  );
};

export default PainPointsSolutions;
