import React from 'react';
import classes from '../../assets/css/components/RelatedSteps.module.scss';
import straightLine from '../../assets/images/DetailedStepsStraightLine.svg';

function RelatedSteps({ stepDescription, backgroundColor, isActive, bottomFlowLine }) {
    return (
        <div data-testid='related-steps'>
            <div className={classes.relatedStepsContainer} style={{ backgroundColor: backgroundColor, opacity: isActive ? "1" : "0.5" }}>
                {stepDescription}
            </div>
            {bottomFlowLine && <div className={classes.flowLine}>
                <img src={straightLine} />
            </div>}
        </div>
    )
}

export default RelatedSteps