import React from 'react';
import tinycolor from 'tinycolor2';
import classes from '../../assets/css/components/DetailedStepsButton.module.scss';

function DetailedStepsButton({ children, backgroundColor, iconUrl, onClick }) {
  return (
    <div
      className={classes.buttonContainer}
      style={{ backgroundColor: backgroundColor }}
      onClick={onClick}
      data-testid={'detailed-steps-button'}
    >
      <div className={classes.buttonContent}>
        {iconUrl && <img className={classes.buttonIcon} src={iconUrl} alt='button-icon' />}
        <span
          style={tinycolor(backgroundColor).isLight() ? { color: '#161616' } : { color: '#fafafa' }}
        >
          {children}
        </span>
      </div>
    </div>
  );
}

export default DetailedStepsButton;
