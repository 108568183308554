import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { getMethod } from '../../services/commonServices';
import FindOutMoreCard from '../../components/FindOutMoreCard/FindOutMoreCard';
import { FindoutAPI } from '../../utils/apiConstant';
import Home from '../../assets/images/home_white.svg';
import JourneyHeader from '../../components/JourneyHeader/JourneyHeader';
import '../../assets/css/components/FindOutMore.scss';
import { useNavigate } from 'react-router';

const FindOutMore = () => {
  const [findOutMore, setfindOutMore] = useState({});
  const [isExit, setExit] = useState(false);
  const navigate = useNavigate();

  const getfindOutResponse = async () => {
    const response = await getMethod(FindoutAPI);
    const formattedResponse = {...response, description: response.description[0]}
    setfindOutMore(formattedResponse);
  };

  useEffect(() => {
    getfindOutResponse();
  }, []);

  const handleTitle = (value) => {
    const words = value.split(' ');
    if (words.length >= 2) {
      words[1] = words[1].toLowerCase();
      const updatedString = words.join(' ');
      return (
        <p className='subtitle subtitle_two_medium lloyds-text-white2 mb-1 mt-1' style={{margin: "0rem"}}>{updatedString}</p>
      );
    }
  };
  return (
    <div
      className={` ${isExit ? 'exitAnimation' : 'entryAnimation'} page-scroll`}
      data-testid='find-out'
    >
      <JourneyHeader 
        imageSrc={Home} 
        title={findOutMore.title}
        onHomeClick={() => {
          setExit(true);
          setTimeout(() => {
            setExit(false);
            navigate("/");
          }, 300);
        }}
       />
      <div className="subtitle-container">
        <div className="mobile-image-container">
          <img src={findOutMore.description?.image} />
        </div>
        <div className='mobile-text-container'>
          {findOutMore.description?.description}
        </div>
      </div>
      <div className="content-container">
        {findOutMore.sections?.map((item, index) => (
          <div key={index} className="section-container">
            <div className="section-title">{handleTitle(item.title)}</div>
              <Grid container spacing={"1.25rem"}>
                {item.items.map((cardData, index) => {
                  return(
                    <Grid item xs={6} key={index}>
                      <FindOutMoreCard
                        icon={cardData.icon}
                        backgroundColor={cardData.backgroundColor}
                        title={cardData.title}
                        description={cardData.items[0].description}
                        qrImage={cardData.items[0].scanningImage}
                      />
                    </Grid>
                  )}
                )}
              </Grid>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FindOutMore;
