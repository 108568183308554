import React from 'react';
import { Grid } from '@mui/material';
import LeftMilestone from './LeftMilestone';
import RightMilestone from './RightMilestone';
import classes from '../../assets/css/components/DetailedStepsSection.module.scss';
import LeftCurveMilestone from './LeftCurveMilestone';
import RightCurveMilestone from './RightCurveMilestone';

function JourneySection({
    subTitle,
    title,
    milestones,
    mainIndex,
    journey,
    page,
    collectionData,
    journeyId,
    category,
    subCategory
}) {
    return (
        <Grid 
            data-testid='journey-section'
            container 
            spacing={"-2px"} 
            sx={{
                background: "linear-gradient(180deg, rgba(250, 250, 250, 0.05) 0%, rgba(250, 250, 250, 0) 100%)",
                backgroundColor: (page === "painPoints" || page === "solutions") ? "rgba(22, 22, 22)" : "",
                paddingTop: (category !== "Placing risk" || subCategory !== "In the open market") && mainIndex === 0 ? "8rem" : "0rem",
                paddingBottom: mainIndex === journey.length-1 ? "80rem" : "0rem",
                }}>
        <Grid item xs={2}>
          <div className={classes.titleContainer} style={{margin: "2.5rem 0rem 0rem 2.5rem", width: "12rem"}}>
            <div className={classes.subTitlePrefix}>{subTitle}</div>
             <div className={classes.subTitle}>{title}</div>
           </div>
         </Grid>
         <Grid item xs={5}>
            <div key={mainIndex}>
               {milestones.map((milestone, index) => {
                   const milestoneObject = collectionData?.milestones.find(
                           (milestoneObj) => milestoneObj.milestoneId === milestone.milestoneId && milestoneObj.journeyId === journeyId
                       );
                    
                       if (milestoneObject) {
                            if (category === "Placing risk" && subCategory === "In the open market") {
                                return(
                                    <div key={milestone.milestoneId}>
                                        {milestone.cssClasses[0] === "left" &&
                                            <LeftMilestone 
                                                milestones={milestones}
                                                title={milestoneObject.title}
                                                image={milestone.image}
                                                index={index}
                                                isStartOrEnd={(index === 0 && mainIndex === 0) || (index === milestones.length-1 && mainIndex === journey.length-1)}
                                                page={page}
                                                inActive={!milestoneObject.isActive}
                                                description={milestoneObject.description}
                                            />
                                         }
                                    </div>
                                )
                            } else {
                                return(
                                    <div key={milestone.milestoneId}>
                                        {milestone.cssClasses[0] === "left" &&
                                            <LeftCurveMilestone 
                                                milestones={milestones}
                                                title={milestoneObject.title}
                                                image={milestone.image}
                                                index={index}
                                                isStart={index === 0 && mainIndex === 0}
                                                isEnd={index === milestones.length-1 && mainIndex === journey.length-1}
                                                page={page}
                                                inActive={!milestoneObject.isActive}
                                                description={milestoneObject.description}
                                            />
                                         }
                                    </div>
                                )
                            }
                       } else {
                            if (category === "Placing risk" && subCategory === "In the open market") {
                                return(
                                   <div key={milestone.milestoneId}>
                                       {milestone.cssClasses[0] === "left" &&
                                           <LeftMilestone 
                                               milestones={milestones}
                                               title={milestone.title}
                                               image={milestone.image}
                                               index={index}
                                               isStartOrEnd={(index === 0 && mainIndex === 0) || (index === milestones.length-1 && mainIndex === journey.length-1)}
                                           />
                                        }
                                   </div>
                                )
                            } else {
                                return(
                                    <div key={milestone.milestoneId}>
                                       {milestone.cssClasses[0] === "left" &&
                                           <LeftCurveMilestone 
                                                milestones={milestones}
                                                title={milestone.title}
                                                image={milestone.image}
                                                index={index}
                                                isStart={index === 0 && mainIndex === 0}
                                                isEnd={index === milestones.length-1 && mainIndex === journey.length-1}
                                           />
                                        }
                                   </div>
                                )
                            }
                       }
                 })}
                {milestones.length%3 !== 0 && mainIndex !== journey.length-1 && category === "Placing risk" && subCategory === "In the open market" &&
                   <LeftMilestone milestones={[]} milestone={{}} index={0} fillingLine={true} />
                }
            </div>
         </Grid>
         <Grid item xs={5}>
         <div key={mainIndex}>
              {milestones.map((milestone, index) => {
                  const milestoneObject = collectionData?.milestones.find(
                    (milestoneObj) => milestoneObj.milestoneId === milestone.milestoneId && milestoneObj.journeyId === journeyId
                );

                if (milestoneObject) {
                    if (category === "Placing risk" && subCategory === "In the open market") {
                        return(
                            <div key={milestone.milestoneId}>
                                {milestone.cssClasses[0] === "right" &&
                                    <RightMilestone 
                                        title={milestoneObject.title}
                                        image={milestone.image}
                                        index={index}
                                        isEnd={index === milestones.length-1 && mainIndex === journey.length-1}
                                        inActive={!milestoneObject.isActive}
                                        page={page}
                                        description={milestoneObject.description}
                                    />
                                }
                            </div>
                        )
                    } else {
                        return(
                            <div key={milestone.milestoneId}>
                                {milestone.cssClasses[0] === "right" &&
                                    <RightCurveMilestone 
                                        title={milestoneObject.title}
                                        image={milestone.image}
                                        index={index}
                                        mainIndex={mainIndex}
                                        isEnd={index === milestones.length-1 && mainIndex === journey.length-1}
                                        inActive={!milestoneObject.isActive}
                                        page={page}
                                        description={milestoneObject.description}
                                    />
                                }
                            </div>
                        )
                    }
                } else {
                    if (category === "Placing risk" && subCategory === "In the open market") {
                        return(
                            <div key={milestone.milestoneId}>
                                {milestone.cssClasses[0] === "right" &&
                                    <RightMilestone 
                                        title={milestone.title}
                                        image={milestone.image}
                                        index={index}
                                        isEnd={index === milestones.length-1 && mainIndex === journey.length-1}
                                    />
                                }
                            </div>
                        )
                    } else {
                        return(
                            <div key={milestone.milestoneId}>
                                {milestone.cssClasses[0] === "right" &&
                                    <RightCurveMilestone 
                                        title={milestone.title}
                                        image={milestone.image}
                                        index={index}
                                        mainIndex={mainIndex}
                                        isEnd={index === milestones.length-1 && mainIndex === journey.length-1}
                                    />
                                }
                            </div>
                        )
                    }
                }
             })}
            </div>
        </Grid>
      </Grid>
    )
  }

export default JourneySection