import React from 'react';
import classes from '../../assets/css/components/LeftMilestone.module.scss';
import CurveLine from '../../assets/images/CurveLine.svg';
import CurveStraightLine from '../../assets/images/CurveStraightLine.svg';
import { lineBreak } from '../../utils/lineBreak';

function LeftMilestone({
    milestones,
    title,
    image,
    index,
    fillingLine,
    isStartOrEnd,
    page,
    inActive,
    description
}) {
  return (
    <div data-testid='left-milestone' className={classes.mainContainer} style={{marginTop: (milestones[index-1]?.cssClasses[0] === "right" || fillingLine) && "24.25rem"}}>
        <div className={classes.bgLineContainer}>
            <img 
                className={classes.bgLine} 
                src={(isStartOrEnd && milestones[index-1]?.cssClasses[0] !== "left") ? CurveStraightLine : CurveLine} 
                style={{
                    transform: (milestones[index-1]?.cssClasses[0] === "right" || fillingLine) && "scaleY(-1)"
                    }} 
            />
        </div>
        {!fillingLine && 
            <div 
                className={classes.contentContainer} 
                style={milestones[index-1]?.cssClasses[0] === "right" ? {
                    bottom: (page === "painPoints" || page === "solutions") ? inActive ? "2rem" : "1rem" : "4rem",
                    opacity: inActive ? "0.4" : "1",
                    } : {
                    top: (page === "painPoints" || page === "solutions") ? inActive ? "3rem" : isStartOrEnd ? "1rem" : "1rem" : "4rem",
                    opacity: inActive ? "0.4" : "1",
                }}
            >
                <div className={classes.imageContainer}>
                    <img className={classes.contentImage} src={image} />
                </div>
                {(page === "painPoints" || page === "solutions") && !inActive ? 
                <div className={page === "painPoints" ? classes.painPointsContainer : classes.solutionsContainer}>
                    <div className={classes.title}>
                        {lineBreak(title)}
                    </div>
                    <div className={classes.description}>
                        {lineBreak(description)}
                    </div>
                </div>
                :
                <div className={classes.contentTitle}>
                    {lineBreak(title)}
                </div>}
            </div>
        }
    </div>
  )
}

export default LeftMilestone;