import React, { useState, useRef, useEffect } from 'react';
import { IdleTimerProvider } from 'react-idle-timer';
import Carousel from 'react-material-ui-carousel';
import { getMethod } from '../../services/commonServices';
import { ScreensaverAPI } from '../../utils/apiConstant';
import '../../assets/css/pages/Screensaver.scss';

export default function Screensaver() {
  const [modalopen, setModalopen] = useState();
  const [src, setSrc] = useState([]);
  const idleTimeRef = useRef(null);

  useEffect(() => {
    getMethod(ScreensaverAPI)
      .then((res) => {
        const imageUrl = res.collection.map((o) => o.image);
        setSrc(imageUrl);
      })
      .catch((err) => console.log(err));
  }, []);

  const onIdle = () => {
    setModalopen(true);
  };

  const onActive = () => {
    setModalopen(false);
  };
  const onAction = () => {};

  return (
    <div
      className={`img-container ${modalopen === true ? '' : 'screensaver-hidden'}`}
      data-testid='main-component'
    >
      <IdleTimerProvider
        timeout={240000}
        ref={idleTimeRef}
        onIdle={onIdle}
        onActive={onActive}
        onAction={onAction}
      ></IdleTimerProvider>
      {modalopen === true && (
        <Carousel
          autoPlay={true}
          interval={3000}
          duration={600}
          animation={'slide'}
          cycleNavigation={true}
          navButtonsAlwaysInvisible={true}
          swipe={false}
        >
          {src &&
            src.map((each, index) => (
              <img
                className='screensaver'
                key={index}
                src={each}
                title={`Title of Image - ${index}`}
                alt={`image-${index}`}
              />
            ))}
        </Carousel>
      )}
    </div>
  );
}
