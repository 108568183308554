import React from 'react';
import Screensaver from './pages/Screensaver/Screensaver';
import './App.scss';
import LandingPage from './pages/LandingPage/LandingPage';
import LandingPageContextProvider from './context/LandingPageContextProvider';
import JourneyOverview from './pages/JourneyOverview/JourneyOverview';
import DetailedSteps from './pages/DetailedSteps/DetailedSteps';
import Stories from './pages/StoryPage/StoryPage';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import PainPointsSolutions from './pages/PainPointsSolutions/PainPointsSolutions';
import JourneyContextProvider from './context/JourneyContextProvider';
import StoryContextProvider from './context/StoryContextProvider';
import { FasterClaims } from './pages/FasterClaims/FasterClaims';
import FindOutMore from './pages/FindOutMore/FindOutMore';
import Glossary from './pages/Glossary/Glossary';
import ErrorPage from './pages/ErrorPage/ErrorPage';
import PPL from './pages/PPL/PPL';

function App() {
  return (
    <div className='App' data-testid='app'>
      <Screensaver />
      <Router>
        <LandingPageContextProvider>
          <Routes>
            <Route path='/' element={<LandingPage />} />
            <Route path='/detailedsteps' element={<DetailedSteps />} />
            <Route path='/ppl' element={<PPL />} />
            <Route element={<StoryContextProvider />}>
              <Route path='/stories' element={<Stories />} />
            </Route>
            <Route path='/DeliveredInProgress' element={<FasterClaims />} />
            <Route path='/Findoutmore' element={<FindOutMore />} />
            <Route path='/Glossary' element={<Glossary />} />

            <Route element={<JourneyContextProvider />}>
              <Route path='/journey' element={<JourneyOverview />} />
              <Route path='/journey/painpointsolutions' element={<PainPointsSolutions />} />
            </Route>
            <Route path='/errorPage' element={<ErrorPage />} />
          </Routes>
        </LandingPageContextProvider>
      </Router>
    </div>
  );
}

export default App;
