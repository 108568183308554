import React from 'react';
import classes from '../../assets/css/components/DetailedStepsSection.module.scss';
import arrow from '../../assets/images/Button_prefix_arrow.svg';

import { Grid } from '@mui/material';
import DetailedStepsDetailCard from '../DetailedStepsDetailCard/DetailedStepsDetailCard';

function DetailedStepsSection({
  subTitlePrefix,
  subTitle,
  imgUrl,
  detailCards,
  statusCollection,
  filterValue,
  isLastSection,
  dataDocumentFilter,
  onCardButtonClick,
  onSummaryButtonClick,
}) {
  return (
    <Grid container columnSpacing="3.75rem" data-testid='detailed-steps-section'>
      <Grid item xs={3}>
        <div className={classes.titleContainer}>
          <div className={classes.subTitlePrefix}>{subTitlePrefix}</div>
          <div className={classes.subTitle}>{subTitle}</div>
          <div className={classes.imageContainer}>
            <img className={classes.image} src={imgUrl} alt='subtitle-image' />
          </div>
        </div>
      </Grid>
      <Grid item xs={9}>
        {detailCards.map((detailCard, index) => {
          return (
            <DetailedStepsDetailCard
              key={detailCard.cardId}
              backgroundColor={
                detailCard.panelStatus.find((status) => status === statusCollection[1])
                  ? '#54a6ff'
                  : detailCard.panelStatus.find(
                      (status) => status === statusCollection[2] || status === 'Underwriter',
                    )
                  ? '#ffbb54'
                  : '#a1a1a2'
              }
              title={detailCard.title}
              buttonColor='#282f54'
              buttonIcon={arrow}
              buttonName='Summary'
              onButtonClick={() => onSummaryButtonClick(detailCard.summaryButton,
                detailCard.panelStatus.find((status) => status === statusCollection[1])
                ? '#54a6ff'
                : detailCard.panelStatus.find(
                    (status) => status === statusCollection[2] || status === 'Underwriter',
                  )
                ? '#ffbb54'
                : '#a1a1a2')}
              cards={
                dataDocumentFilter
                  ? detailCard.cardItemsList.filter(
                      (item) =>
                        item.collectionType === dataDocumentFilter.split(' ')[0] ||
                        !item.collectionType,
                    )
                  : detailCard.cardItemsList
              }
              isActive={
                filterValue === 'All steps'
                  ? true
                  : filterValue === 'Insurer'
                  ? detailCard.panelStatus.includes('Underwriter')
                  : detailCard.panelStatus.includes(filterValue)
              }
              bottomFlowLine={!isLastSection || index !== detailCards.length - 1}
              onCardButtonClick={(value) => onCardButtonClick(value)}
            />
          );
        })}
      </Grid>
    </Grid>
  );
}

export default DetailedStepsSection;
